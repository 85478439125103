<template>
    <a-row align='center' justify='end' dense>

        <a-col cols='12'>
            <div class='d-flex align-center'>
                <div class='title'>
                    {{$t('REPORTS')}}
                </div>

                <a-spacer></a-spacer>

                <a-btn add @click='dialog = true'>
                    {{$t('NEW_REPORT')}}
                </a-btn>
            </div>
        </a-col>

        <a-col cols='12'>
            <draggable tag='a-row' :list='pivots' hadle='.drag-handler' @sort='updatePivotOrder'>
                <a-col
                    v-for='pivot in pivots'
                    :key='pivot.reportId'
                    cols='12'
                >
                    <a-card outlined :loading='loading'>

                        <a-card-title>
                            <a-icon
                                left
                                class='drag-handler'
                            >
                                drag_indicator
                            </a-icon>

                            {{pivot.name}}

                            <a-spacer></a-spacer>
                            <a-btn icon color-icon='mono-grey-40' @click='editPivot(pivot)'>
                                <a-icon>edit</a-icon>
                            </a-btn>
                            <a-btn icon color-icon='mono-grey-40' @click='deletePivot(pivot)'>
                                <a-icon>delete</a-icon>
                            </a-btn>
                        </a-card-title>

                        <a-divider></a-divider>

                        <a-card-text style='min-height:150px'>
                            <data-table-report-pivot
                                v-if='!loading'
                                :_options='pivot.options'
                                :_items='_items'
                                :_edit-mode='false'
                                :_show-banner='false'
                                :_report-name='pivot.name'
                            />
                        </a-card-text>
                    </a-card>
                </a-col>

                <a-col v-if='!pivots.length' cols='12'>
                    <a-row align='center' justify='center'>
                        <a-img contain aspect-radioaspect-ratio='1' max-width='600px' :src='$_aura.getImage("empty-reports")' />
                    </a-row>
                    <a-row align='center' justify='center'>
                        <label>{{$t('NO_REPORTS')}}</label>
                    </a-row>
                </a-col>
            </draggable>
        </a-col>

        <data-table-report-form
            v-model='dialog'
            :_items='_items'
            :_command-name='_commandName'
            :_preference-key='_preferenceKey'
            :_edited-item='editedItem'
            @save='load'
        />
    </a-row>
</template>

<script>
    import { GetUserConfigs, DeleteUserConfig, SaveUserConfig } from '@/components/core/services/request.ts';
    import { PREFERENCES_KEYS } from './services/consts';

    export default {
        name: 'DataTableReport',
        components: {
            DataTableReportForm: () => import('./DataTableReportForm'),
            DataTableReportPivot: () => import('@/components/core/dataTableReport/DataTableReportPivot'),
            draggable: () => import('vuedraggable'),
        },
        props: {
            _title: {
                type: String,
                default: undefined,
            },
            _commandName: {
                type: String,
                default: undefined,
            },
            _items: {
                type: Array,
                default: () => [],
            },
            _preferenceKey: {
                type: String,
                default: undefined,
            },
        },
        data: function () {
            return {
                dialog: false,
                loading: false,
                pivots: [],
                pivotsOrder: [],
                editedItem: null,
            };
        },
        computed: {
            preferenceName: function () {
                if (this._preferenceKey) {
                    return this._preferenceKey;
                }

                return this._commandName;
            },
        },
        watch: {
            dialog: function (value) {
                if (!value) {
                    this.editedItem = null;
                }
            },
            '_items.length': 'load',
        },
        created: function () {
            this.load();
        },
        methods: {
            load: async function () {
                try {
                    this.loading = true;

                    await this.setPivotOrder();

                    await this.setPivots();

                    setTimeout(() => {
                        this.loading = false;
                    }, 800);
                } catch (error) {
                    this.loading = false;
                    throw new Error(error);
                }
            },
            setPivots: async function () {
                const command = new GetUserConfigs();

                const request = await command.callCommand({
                    name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT}:${this.preferenceName}:*`,
                });

                const items = this.$util.getArrayByNode(request, command.node).map(item => {
                    if (this.$util.isJsonString(item.VALOR)) {
                        return JSON.parse(item.VALOR);
                    }

                    return item.VALOR;
                });

                this.pivots = this.$lodash.orderBy(items, i => this.pivotsOrder.indexOf(i.reportId));
            },
            setPivotOrder: async function () {
                const command = new GetUserConfigs();

                const request = await command.callCommand({
                    name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT_ORDER}:${this.preferenceName}`,
                });

                const item = this.$util.getObjectByNode(request, command.node);

                if (!item || !item.VALOR) {
                    return;
                }

                if (this.$util.isJsonString(item.VALOR)) {
                    item.VALOR = JSON.parse(item.VALOR);
                }

                this.pivotsOrder = item.VALOR;
            },
            updatePivotOrder: async function () {
                const command = new SaveUserConfig();
                const order = this.pivots.map(p => p.reportId);

                await command.callCommand(
                    {
                        name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT_ORDER}:${this.preferenceName}`,
                        value: order,
                    },
                    { silent: true },
                );
            },
            editPivot: function (item) {
                this.editedItem = item;
                this.dialog = true;
            },
            deletePivot: async function (pivot) {
                const { value } = await this.$_aura.swalDelete();

                if (!value) {
                    return;
                }

                const command = new DeleteUserConfig();

                await command.callCommand({
                    name: pivot.reportId,
                });

                await this.load();
            },
        },
    };
</script>
