<template>
    <div ref='chartRef' style='height:100%; width:100%;' />
</template>

<script>
    import echarts from 'echarts';

    export default {
        name: 'Chart',
        props: {
            /**
             * Options object of echarts
             * Is the object that goes in the setOption() of echarts instance
             * @type {Object}
             * @default {null}
             */
            _options: {
                type: Object,
                required: false,
                default: null,
            },
            /**
             * Object of x-axis or an array of x-axis
             * Is required when do not have _options props seted
             * @type {Object, Arrya}
             * @default {null}
             */
            _xAxis: {
                type: [Object, Array],
                required: false,
                default: null,
            },
            /**
             * Object of y-axis or an array of y-axis
             * Is required when do not have _options props seted
             * @type {Object, Arrya}
             * @default {null}
             */
            _yAxis: {
                type: [Object, Array],
                required: false,
                default: null,
            },
            /**
             * Name of the group of echarts instance, is used to connect the echarts instances
             * If is seted the component will connect with the others charts using the group name
             * @type {String}
             * @default {null}
             */
            _group: {
                type: String,
                required: false,
                default: null,
            },
            /**
             * Array of colors in series
             * If is seted the component will connect with the others charts using the group name
             * @type {String}
             * @default {null}
             */
            _colors: {
                type: Array,
                required: false,
                default: null,
            },
            /**
             * Canvas Height
             * Is the height of the canvas html element
             * @type {[String, Number]}
             * @default {auto}
             */
            _canvasHeight: {
                type: [String, Number],
                required: false,
                default: 'auto',
            },
            /**
             * Canvas Width
             * Is the width of the canvas html element
             * @type {[String, Number]}
             * @default {auto}
             */
            _canvasWidth: {
                type: [String, Number],
                required: false,
                default: 'auto',
            },
        },
        data: function () {
            return {
                chart: null,
                chartOptions: null,
                defaultColors: [
                    this.$vuetify.theme.currentTheme.primary,
                    this.$vuetify.theme.currentTheme.success,
                    this.$vuetify.theme.currentTheme.warning,
                    this.$vuetify.theme.currentTheme.purple,
                    this.$vuetify.theme.currentTheme.danger,
                    this.$vuetify.theme.currentTheme.orange,
                    this.$vuetify.theme.currentTheme.pink,
                    this.$vuetify.theme.currentTheme.info,
                    this.$vuetify.theme.currentTheme.secondary,
                    this.$vuetify.theme.currentTheme['dark-blue'],
                    this.$vuetify.theme.currentTheme['dark-green'],
                ],
                reloadTimeout: null,
                ro: null,
            };
        },
        computed: {
            loaded: function () {
                if (this.chart) {
                    return true;
                }

                return false;
            },
        },
        watch: {
            _options: {
                deep: true,
                handler: 'setOption',
            },
            _group: 'setChart',
        },
        created: function () {
            this.$nextTick(() => {
                this.ro = new ResizeObserver(() => {
                    if (this.reloadTimeout) {
                        clearTimeout(this.reloadTimeout);
                    }

                    this.reloadTimeout = setTimeout(() => {
                        this.resize();
                    }, 500);
                });

                this.ro.observe(this.$refs.chartRef);
            });
        },
        mounted: function () {
            this.load();
        },
        beforeDestroy: function () {
            if (this.chart) {
                this.chart.clear();
                this.chart.dispose();
            }
        },
        methods: {
            load: function () {
                // set the chart in dom element
                this.setChart();
                // set default options
                this.loadDefaultOptions();
                // emit event onload
                this.$emit('onload');
            },
            setChart: function () {
                if (!this.chart) {
                    this.chart = echarts.init(this.$refs.chartRef, undefined, {
                        width: this._canvasWidth,
                        height: this._canvasHeight,
                    });
                }
                if (this._group) {
                    this.chart.group = this._group;
                }
            },
            loadDefaultOptions: function () {
                if (this._options) {
                    const options = JSON.parse(JSON.stringify(this._options));
                    this.chartOptions = options;
                    this.setOption(this.chartOptions);
                } else {
                    if (!this._xAxis) {
                        throw new Error('Chart -> loadDefaultOptions() missing xAxis');
                    }
                    if (!this._yAxis) {
                        throw new Error('Chart -> loadDefaultOptions() missing yAxis');
                    }
                    const options = {
                        yAxis: this._yAxis,
                        xAxis: this._xAxis,
                    };
                    this.chartOptions = options;

                    this.setOption(this.chartOptions);
                }
            },
            setOption: function (options, opts) {
                // add color palete in chart
                options = { ...options, color: this._colors || this.defaultColors };
                // set chart options
                this.chart.setOption(options, opts);
                // if have a group call function to connect the charts
                if (this.chart.group) {
                    echarts.connect(this.chart.group);
                }
            },
            // reset chart to init state
            reset: function () {
                this.chart.clear();
                this.loadDefaultOptions();
            },
            // reload the configurations
            reload: function () {
                this.setOption(this.chartOptions);
            },
            /**
             * Series functions
             * =============================================================
            */
            setSeries: function (series) {
                this.chartOptions.series = Object.freeze(series);
                this.reload();
            },
            addSerie: function (serie) {
                if (!serie.id) {
                    throw new Error('Chart -> addSerie() series must have id');
                }
                if (!this.chartOptions.series) {
                    this.chartOptions.series = [];
                }

                this.chartOptions.series.push(serie);

                this.reload();
            },
            removeSerie: function (serieId) {
                if (this.chartOptions.series) {
                    const index = this.chartOptions.series
                        .findIndex(s => s.id === serieId);
                    this.chartOptions.series.splice(index);
                }

                this.reload();
            },
            /**
             * Series functions
             * =============================================================
            */
            setTooltip: function (tooltip) {
                this.chartOptions.tooltip = tooltip;
                this.reload();
            },
            /**
             * Generic functions
             * =============================================================
            */
            getChart: function () {
                return this.chart;
            },
            setProperty: function (propertyName, propertyValue) {
                this.chartOptions[propertyName] = propertyValue;
                this.reload();
            },
            removeProperty: function (propertyName) {
                delete this.chartOptions[propertyName];
                this.reload();
            },
            resize: function (options) {
                this.chart.resize(options);
            },
        },
    };
</script>
