<template>
    <v-slide-y-transition>
        <a-toolbar
            :class="['a-page-bar', isMobileOrTablet ? 'isMobileOrTablet' : '']"
            min-height='64px'
            height='auto'
            width='100%'
            tile
            flat
            outlined
            style="z-index:2"
        >
            <a-menu
                v-if="currentPageCanFavoritePage"
                v-model='favoriteMenu.show'
                :close-on-content-click='true'
                :max-width='400'
                offset-x
                offset-y
                :position-x='favoriteMenu.x'
                :position-y='favoriteMenu.y'
            >
                <a-card>
                    <a-card-title>
                        <a-icon left color='primary'>
                            mdi-heart
                        </a-icon>
                        <span class='title font-weight-light'>{{$t('FAVORITE')}}</span>
                    </a-card-title>
                    <a-card-text>{{$t('FAVORITE_EXPLANATION', {page: $t(currentPage.title), category: $t(currentPage.favCategory.toUpperCase())})}}</a-card-text>
                </a-card>
            </a-menu>

            <a-container
                :fluid='_containerFluid'
                :class='["d-flex align-center py-2", _containerFlexClass]'
                style='min-height:64px'

            >
                <div class="flex-shrink-0 mb-3 mb-sm-0">
                    <a-btn
                        v-if="_showBackIcon"
                        icon
                        class="mr-0 mr-sm-7"
                        @click="clickBackIcon"
                    >
                        <a-icon>mdi-arrow-left</a-icon>
                    </a-btn>
                    <a-btn
                        v-if="_showFavoriteIcon && currentPageCanFavoritePage"
                        icon
                        class="mr-0 mr-sm-7"
                        @click="saveCurrentPageToFavorites"
                    >
                        <a-icon>{{currentPageIsFavorite ? 'mdi-heart' : 'mdi-heart-outline'}}</a-icon>
                    </a-btn>
                </div>
                <div class='mb-3 mb-sm-0 text-center text-sm-left px-0 px-sm-3'>
                    <h1 v-if='$slots["tag-line"]' class='text-caption grey--text'>
                        <slot name='tag-line' />
                    </h1>
                    <h2 v-else-if="_tagline" class="text-caption grey--text" v-text="_tagline" />
                    <h1 v-if='$slots.title' class='text-h5'>
                        <slot name='title' />
                    </h1>
                    <h1 v-else-if="barTitle" class='text-h5' v-text="barTitle" />
                </div>
                <slot name='action-entity' />
                <a-spacer />
                <slot />
                <span>
                    <help-menu v-if='_showHelp' :_screenName="currentPage.componentName" :_pageProps='currentPage.props' />
                    <info-details :_info='pageInfo' :_disable='!_showInfo'/>
                </span>
            </a-container>
        </a-toolbar>
    </v-slide-y-transition>
</template>

<script>
    import HomeConts from '@/pages/home/services/consts.ts';

    export default {
        components: {
            HelpMenu: () => import('@/pages/help/HelpMenu.vue'),
            InfoDetails: () => import('@/pages/info/InfoDetails.vue'),
        },
        props: {
            _title: {
                type: [String, Number],
                required: false,
                default: null,
            },
            _tagline: {
                type: String,
                required: false,
                default: null,
            },
            _showBackIcon: {
                type: Boolean,
                required: false,
                default: false
            },
            _showFavoriteIcon: {
                type: Boolean,
                required: false,
                default: true
            },
            _containerFluid: {
                type: Boolean,
                required: false,
                default: false
            },
            _containerFlexClass: {
                type: String,
                default: 'flex-column flex-sm-row'
            },
            _showHelp: {
                type: Boolean,
                required: false,
                default: true,
            },
            _showInfo: {
                type: Boolean,
                required: false,
                default: true,
            },
            _pageInfo: {
                type: String,
                required: false,
                default: null,
            }
        },
        data: function (){
            return {
                currentPage: null,
                showFavoriteMenu: false,
                favoriteMenu: {
                    show: false,
                    y: 0,
                    x: 0
                }
            }
        },
        computed: {
            isMobileOrTablet: function () {
                const breakpoints = [
                    'xs',
                    'sm',
                ]

                return breakpoints.includes(this.$vuetify.breakpoint.name)
            },
            barTitle: function () {
                if (this._title) {
                    return this._title;
                }

                if (this.currentPage) {
                    return this.currentPage.title;
                }
            },
            currentPageCanFavoritePage: function () {
                if (!this.currentPage) {
                    return false;
                }

                if (!this.currentPage.favCategory) {
                    return false;
                }

                return true;
            },
            currentPageIsFavorite: function () {
                if (!this.currentPage) {
                    return false;
                }
                const found = this.$_aura.user.favoriteRegisters.find(reg => {
                    return this.currentPage.componentName === reg.name && this.$lodash.isEqual(this.currentPage.props, reg.props);
                });

                return !!found;
            },
            pageInfo: function () {
                if (this._pageInfo) {
                    return this._pageInfo;
                }
                return '';
            }
        },
        created: function (){
            this.load()
        },
        methods: {
            load(){
                const pageName = this.$store.getters.getCurrentPage;
                let currentPage = this.$store.getters.getPages.find(page => page.name === pageName);

                if (currentPage.componentName === HomeConts.HOME) {
                    currentPage = this.$store.getters.getHomePage;
                }

                this.currentPage = currentPage || null;
            },
            async saveCurrentPageToFavorites(e){
                const pageMetaData = {
                    name: this.currentPage.componentName,
                    props: this.currentPage.props,
                    title: this.currentPage.title,
                    type: 'register',
                    favCategory: this.currentPage.favCategory,
                }

                this.favoriteMenu.x = e.clientX;
                this.favoriteMenu.y = e.clientY;


                if (this.currentPageIsFavorite) {
                    await this.$_aura.events.global.$emit('DELETE_FAVORITE', pageMetaData);
                } else {
                    await this.$_aura.events.global.$emit('SAVE_FAVORITE', pageMetaData);
                    this.favoriteMenu.show = true;
                }

                this.$emit('added-to-favorite', pageMetaData)

                this.load();
            },
            clickBackIcon(){
                if (this.$listeners['click-back-icon']) {
                    return this.$emit('click-back-icon')
                }
                this.$util.closePage();
            },
        }
    };
</script>

<style lang='scss'>
.application .v-toolbar.a-page-bar {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    position: sticky;
    top: 0;

    &.isMobileOrTablet {
        position: relative;
    }

    .v-toolbar__content {
        padding: 0px 0;
    }
    .text-caption, .text-h5 {
        font-family: 'Graphik', sans-serif !important;
        word-break: break-word;
    }
}

</style>
