<template>
    <div id='app'>
        <a-app
            v-if='dialogLogin'
            class='application'
        >
            <login />
        </a-app>
        <a-app v-else class='application'>
            <a-progress-circular
                v-if='runningRequests > 0 && (dialogLogin || $route.query.onlycontent)'
                indeterminate
                color='secondary'
                style='position: absolute; top: 10px; right: 10px; z-index: 9999'
            />

            <app-lateral-menu v-if='!$route.query.onlycontent && $store.getters.getEnableLateralMenu' />

            <app-menu v-if='loadedMenu && !dialogLogin && !$route.query.onlycontent' />

            <app-toolbar v-if='!$route.query.onlycontent && showDrawer' :class='toolbarPadding' :_reset-dialog='resetDialog' />

            <app-general-notice-carrousel
                v-if='hasGeneralNotice'
                :_general-notice-list='generalNoticeList'
                @closeGeneralNotice='closeGeneralNotice'
            />

            <a-overlay v-if='pageWithoutLoad' :value='pageWithoutLoad'>
                <h4>Sua tela não possui o metodo load! Insira ele por favor</h4>
            </a-overlay>

            <a-main :style='hasGeneralNotice && "padding-top: 0px;"'>
                <a-tabs-items v-model='$store.getters.getCurrentPage'>
                    <a-tab-item
                        v-for='page in $store.getters.getPages'
                        :key='page.name'
                        :value='page.name'
                    >
                        <div
                            id='page-wrapper'
                            class='page-wrapper tab-page'
                            :style='pageWrapperStyle'
                        >
                            <component
                                :is='page.component'
                                :key='page.name'
                                :ref='`P:${page.name}`'
                                v-bind='page.props || {}'
                                :_active-page='!$store.getters.getCurrentDialog && page.name === $store.getters.getCurrentPage'
                                :_page-key='page.name'
                                @hook:mounted='pageMounted(`P:${page.name}`, page)'
                                @hook:beforeDestroy='beforeDestroyPage(`P:${page.name}`)'
                                @shortcuts='page.shortcuts = $event'
                            />

                        </div>
                    </a-tab-item>
                </a-tabs-items>

                <a-dialog
                    v-if='resetDialog'
                    :key='keyResetDialog'
                    v-model='resetDialog'
                    persistent
                    width='400'
                >
                    <a-card>
                        <a-card-title>{{resetDialogTitle}} </a-card-title>
                        <a-card-subtitle>{{$t('PLEASE_INSERT_NEW_PASSWORD')}}</a-card-subtitle>
                        <reset-password
                            :_coercive-password-change='true'
                            @save='resetDialog = false'
                        />
                    </a-card>
                </a-dialog>
                <a-dialog
                    v-if='mfaDialog'
                    v-model='mfaDialog'
                    max-width='600'
                    persistent
                >
                    <person-permission-login-m-f-a :_cad-mode='true' :_user-email='userEmail' @updateFlag='reloadFlag' />
                </a-dialog>

                <template v-if='$store.getters.getDialogs'>
                    <a-dialog
                        v-for='(dialog, index) in $store.getters.getDialogs'
                        :key='index'
                        v-model='dialog.value'
                        v-bind='dialog.propsDialog || {}'
                        transition='dialog-bottom-transition'
                    >
                        <a-card class='dialog-page'>
                            <a-card-title>
                                {{dialog.title}}
                                <a-spacer></a-spacer>
                                <a-icon
                                    close
                                    color='mono-grey-50'
                                    :small='false'
                                    @click='$util.closePage'
                                />
                            </a-card-title>
                            <div style='position: relative'>
                                <component
                                    :is='dialog.component'
                                    :ref='`D:${dialog.name}`'
                                    v-bind='dialog.props || {}'
                                    :_active-page='dialog.name === $store.getters.getCurrentDialog'
                                    :_page-key='dialog.name'
                                    @hook:mounted='pageMounted(`D:${dialog.name}`, dialog)'
                                    @hook:beforeDestroy='beforeDestroyPage(`D:${dialog.name}`)'
                                    @shortcuts='dialog.shortcuts = $event'
                                />
                            </div>
                        </a-card>
                    </a-dialog>
                </template>

            </a-main>

            <app-fab />

            <loading
                :value.sync='$store.getters.isLoading'
                :_message='$t("LOADING_")'
                :_progress-color='"#3b6ea7"'
            />

        </a-app>
    </div>
</template>

<!--<script src="https://cdn.lr-in-prod.com/LogRocket.min.js" crossorigin="anonymous"></script>-->
<!--<script>window.LogRocket && window.LogRocket.init('videnci/exati');</script>-->
<script>
    import {
        GetLoginScreenInfo, GetUserHasLateralMenu,
        CheckMfaNeed,
    } from '@components/core/app/services/request';
    import utilApp from '@/components/core/app/services/util';
    import { GetPasswordValidity } from '@/pages/passwordProgramming/services/request.ts';
    import { GetDefaultCurrencies, GetLinkedGeneralNotices } from '@/pages/config/services/request';
    // import LogRocket from 'logrocket';

    export default {
        name: 'App',
        components: {
            AppMenu: () => import('@/components/core/app/AppMenu'),
            AppDrawer: () => import('@components/core/app/AppDrawer'),
            AppToolbar: () => import('@components/core/app/AppToolbar'),
            AppGeneralNoticeCarrousel: () => import('@components/core/app/AppGeneralNoticeCarrousel'),
            AppFab: () => import('@components/core/app/AppFab'),
            Login: () => import('@/components/core/access/Login'),
            Loading: () => import('@components/core/loading/Loading'),
            ResetPassword: () => import('@components/core/password/ResetPassword.vue'),
            AppLateralMenu: () => import('@/components/core/app/AppLateralMenu.vue'),
            PersonPermissionLoginMFA: () => import('@/pages/people/PersonPermissionLoginMFA.vue'),
        },
        data: function () {
            return {
                dialogLogin: false,
                showDrawer: false,
                pageWithoutLoad: false,
                shortcuts: false,
                runningRequests: 0,
                resetDialog: false,
                resetDialogTitle: '',
                keyResetDialog: 0,
                loadedMenu: false,
                mfaDialog: false,
                userEmail: null,
                generalNoticeList: [],
            };
        },
        computed: {
            hasGeneralNotice: function () {
                if (this.generalNoticeList.length === 0 || this.generalNoticeList === undefined || this.generalNoticeList === null) {
                    return false;
                }
                return this.generalNoticeList.length > 0;
            },
            isMini: function () {
                return this.$vuetify.breakpoint.name !== 'xs' && this.$vuetify.breakpoint.name !== 'sm';
            },
            toolbarPadding: function () {
                if (this.$store.getters.getEnableLateralMenu) {
                    if (this.$store.getters.getIsLateraMenuOpen) {
                        return 'pl-256';
                    }
                    return 'pl-84';
                }
                return '';
            },
            currentPage: function () {
                const pageName = this.$store.getters.getCurrentPage;
                if (!pageName) {
                    return null;
                }
                const currentPage = this.$store.getters.getPages.find(page => page.name === pageName);

                return currentPage;
            },
            pageWrapperStyle: function () {
                const excludeComponents = [];

                if (this.currentPage && excludeComponents.includes(this.currentPage.componentName)) {
                    return null;
                }

                if (this.$route.query.onlycontent) {
                    return { 'overflow-y': 'auto', height: '100vh' };
                }

                if (this.$store.state.customWindow.disablePageWrapperScroll) {
                    return {
                        height: 'calc(100vh - 54px)',
                    };
                }

                return {
                    'overflow-y': 'auto',
                    height: 'calc(100vh - 54px)',
                };
            },
        },
        created: async function () {
            // Gambiarra master para que funcione as traducoes dos componentes
            require('@/components').default;

            this.$store.watch(
                (state, getters) => getters.getDialogs,
                () => {
                    if (this.$store.getters.getDialogs.length) {
                        document.getElementsByTagName('html')[0].className = 'html-hide-overflow';
                    } else {
                        document.getElementsByTagName('html')[0].className = '';
                    }
                },
            );
            this.$store.watch(
                (state, getters) => getters.getUser,
                () => {
                    utilApp.setThemeDomain(this.$vuetify.theme.themes, this.$store.getters.getUser, this.$store.getters.getDomain);
                },
            );
            this.$store.watch(
                (state, getters) => getters.getDomain,
                () => {
                    utilApp.setThemeDomain(this.$vuetify.theme.themes, this.$store.getters.getUser, this.$store.getters.getDomain);
                },
            );
        },
        mounted: function () {
            this.logoutMovidesk();
            if (this.$route.query.token) {
                localStorage.setItem('token', this.$route.query.token);
            }

            if (this.$route.name) {
                this.$util.openPage({ name: this.$route.name, persist: true });
            } else {
                this.$util.openPage({ name: 'Home', persist: true });
            }

            this.$_aura.events.global.$on('reloadLogin', this.load);
            this.$_aura.events.global.$on('showLogin', this.showLogin);
            this.$_aura.events.global.$on('openPage', (pageData) => {
                this.$util.openPage(pageData.name, pageData);
            });
            this.load();
            this.$_aura.events.global.$on('updateRequests', this.updateRequests);
        },
        beforeDestroy: function () {
            this.$_aura.events.global.$off('reloadLogin');
            this.$_aura.events.global.$off('showLogin');
            this.$_aura.events.global.$off('updateRequests');
        },
        methods: {
            updateRequests: function (runningRequests) {
                this.runningRequests = runningRequests;
            },
            load: async function () {
                await Promise.all([this.loadLoginStyleName(), this.loadData()]);
                this.onLoadFinished();
                // LogRocket.init('videnci/exati');
            },
            loadData: async function () {
                if (!this.$_aura.getToken()) {
                    return;
                }
                await this.getLoggedUser();
                await this.mfaRequired();
                await this.loadCurrencies();
                await this.getGeneralNotices();
            },
            onLoadFinished: function () {
                this.loadedMenu = true;
            },
            mfaRequired: async function () {
                const resolve = await new CheckMfaNeed().callCommand({});
                if (resolve.RAIZ.REQUER_MFA) {
                    this.userEmail = resolve.RAIZ.EMAIL || null;
                    this.mfaDialog = true;
                }
                this.$forceUpdate();
                this.$nextTick(() => {
                    this.$_aura.events.global.$emit('reloadPageData');
                });
                this.loadHelp();
                this.loadLateralMenu();
            },
            reloadFlag: function () {
                this.mfaDialog = false;
            },
            getLoggedUser: async function () {
                if (!this.$_aura.getToken()) {
                    return;
                }

                this.showDrawer = false;
                await this.$util.getLoggedUser();
                this.$store.commit('SET_USER', this.$_aura.user);
                const validPassword = await this.getPasswordValidity();
                this.setResetDialogTitle(validPassword);
                if (validPassword < 1) {
                    this.$router.push({ query: { resetPassword: true } });
                    this.showLogin(true);
                } else {
                    this.showLogin(false);
                    this.dialogLogin = false;
                    this.$forceUpdate();
                    this.$nextTick(() => {
                        this.showDrawer = true;
                        this.$_aura.events.global.$emit('reloadPageData');
                    });
                    this.loadHelp();
                    this.loadLateralMenu();
                }
                // LogRocket.identify(this.$_aura.user.personId, {
                //     name: this.$_aura.user.name,
                //     email: this.$_aura.user.email,
                //     companyId: this.$_aura.user.companyId,
                // });
            },
            loadHelp: async function () {
                try {
                    await this.$store.dispatch('loadHelpOptions');
                } catch (e) {}
            },
            loadCurrencies: async function () {
                const resolve = await new GetDefaultCurrencies().callCommand();
                this.$store.dispatch('SET_CURRENCY', {
                    code: resolve.RAIZ.PADRAO.CODIGO,
                    locale: resolve.RAIZ.PADRAO.SIGLA,
                    symbol: resolve.RAIZ.PADRAO.CIFRAO,
                });
            },
            loadLoginStyleName: async function () {
                let loginStyleName = this.$store.getters.getLoginStyleName;
                if (!loginStyleName) {
                    const response = await new GetLoginScreenInfo().callCommand();
                    loginStyleName = this.$lodash.get(response, 'RAIZ.CONFIG.ID_ESTILO_DOMINIO_LOGIN', 'general');
                    this.$store.commit('SET_LOGIN_STYLE_NAME', loginStyleName);
                }
            },
            loadLateralMenu: async function () {
                if (!this.$store.getters.getUser) {
                    return;
                }
                const { userId } = this.$store.getters.getUser;
                const response = await new GetUserHasLateralMenu().callCommand({
                    userId: userId,
                });
                const enableLateralMenu = this.$lodash.get(response, 'RAIZ.USUARIO.POSSUI_MENU_LATERAL');
                if (enableLateralMenu) {
                    this.$store.commit('SET_ENABLE_LATERAL_MENU', enableLateralMenu);
                }
            },
            getPasswordValidity: async function () {
                await this.$util.getLoggedUser();
                const { userId } = this.$_aura.user;
                const resolve = await new GetPasswordValidity().callCommand({ userId: userId });
                const response = this.$_aura.getObject(resolve.RAIZ.USUARIO);
                return response.SENHA_VALIDA;
            },
            getGeneralNotices: async function () {
                await this.$util.getLoggedUser();
                const { userId } = this.$_aura.user;
                try {
                    const resolve = await new GetLinkedGeneralNotices().callCommand({
                        userId: userId,
                    });
                    const response = this.$_aura.getArray(() => resolve.RAIZ.AVISOS_GERAIS.AVISO_GERAL);

                    this.generalNoticeList = response;
                } catch (error) {
                }
            },
            closeGeneralNotice: function (item) {
                this.generalNoticeList = this.generalNoticeList.filter((generalNotice) => generalNotice.ID_AVISO_GERAL !== item.ID_AVISO_GERAL);
            },
            setResetDialogTitle: function (validPassword) {
                // validPassword === 0 -> password expired
                // validPassword === -1 -> redefine password on first login
                if (validPassword !== 1) {
                    this.resetDialog = true;
                } else {
                    this.resetDialog = false;
                }
                this.resetDialogTitle = validPassword === 0 ? this.$t('YOUR_PASSWORD_HAS_EXPIRED') : this.$t('REDEFINE_YOUR_PASSWORD');
                this.keyResetDialog += 1;
            },
            logoutMovidesk: function () {
                try {
                    document.getElementById('md-app-widget').style.display = 'none';
                } catch (e) {

                }
            },
            showLogin: function (requestLogin) {
                if (this.$router.currentRoute.name !== 'login'
                    && this.$router.currentRoute.name !== 'BudgetCodePage'
                    && (this.$router.currentRoute.name !== 'MaintenanceSatisfactionSurveyForm' || !this.$route.query.claimantCode)) {
                    this.dialogLogin = requestLogin;
                    this.logoutMovidesk();
                }
            },
            pageMounted: function (ref, page) {
                const component = this.$refs[ref][0];

                if (!Object.keys(component).includes('load')) {
                    const isDev = process.env.NODE_ENV === 'development';
                    if (isDev) {
                        this.pageWithoutLoad = true;
                        console.error(`[ERRO]\nAtenção! \nSua tela '${ref}' não possui um método de 'load'. Adicione-o imediatamente.\n\nAdicione:\nload: function () {} // utilize esta função para carregar os dados da tela. \nSaiba mais no readme do projeto`); // eslint-disable-line no-console
                    }
                } else {
                    this.$_aura.events.global.$on('reloadLogin', component.load);
                }
                if (page.events) {
                    const { events } = page;
                    const { length } = events;
                    for (let i = 0; i < length; i++) {
                        const ev = events[i];
                        component.$on(ev.name, ev.callback);
                    }
                }

                this.$util.hideLoading();
            },
            beforeDestroyPage: function (ref) {
                const components = this.$refs[ref];
                if (components) {
                    components.forEach(component => {
                        component.$emit('close-page');
                    });
                }
            },
        },
    };
</script>

<style lang='scss'>
    @import './src/style/app.scss';
    @font-face {
        font-family: "Graphik";
        src: local("Graphik"),
        url(fonts/Graphik/Graphik_Regular.otf) format("truetype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Graphik";
        src: local("Graphik"),
        url(fonts/Graphik/Graphik_Bold.otf) format("truetype");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Graphik";
        src: local("Graphik"),
        url(fonts/Graphik/Graphik_Medium.otf) format("truetype");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Graphik";
        src: local("Graphik"),
        url(fonts/Graphik/Graphik_Light.otf) format("truetype");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Roboto Mono';
        src: local("Roboto Mono"),
        url(fonts/Roboto-Mono/RobotoMono-VariableFont_wght.ttf) format("truetype");
        font-weight: 400;
        font-style: normal;
    }
</style>
