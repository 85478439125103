<template>
    <a-container ref='container' v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getMaintenancePerDistrict.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            :_fab='false'
            :_command='getMaintenancePerDistrict'
            @updateValues='updateDashData'
        />
        <a-row no-gutters>
            <a-col>
                <a-radio-group v-model='chartType' row>
                    <a-radio :label='$t("BY_ENTITY", { entity: $t("REGION") })' :value='2' />
                    <a-radio :label='$t("BY_ENTITY", { entity: $t("DISTRICT") })' :value='1' />
                </a-radio-group>
            </a-col>
        </a-row>
        <chart
            v-show='!emptyData && !loading'
            ref='chart'
            :_options='chartOptions'
        />
    </a-container>
</template>

<script>
    import { GetMaintanancePerDistrict } from './services/request';

    export default {
        name: 'MaintenancePerDistrictChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },
            /**
             * Command filter values sent from the MaintenancePerDistrict page to fill the getMaintenancePerDistrict fields
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * Data values sent from the MaintenancePerDistrict page to the chart
             * @type {Array}
             * @default {{}}
             */
            _chartData: {
                type: Object,
                default: () => ({}),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                categoryData: [],
                reportData: [],
                chartType: 2,
                filters: [],

                // Chart instance, used for the on() event
                chart: undefined,

                // Default chart before it's loaded with data
                chartOptions: {
                    xAxis: {
                        type: 'category',
                        name: this.timeDescription,
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('ATTENDANCES'),
                    },
                },

                totals: {
                    attendances: 0,
                    assets: 0,
                    lightSpots: 0,
                    percentage: 0,
                },
            };
        },
        computed: {
            getMaintenancePerDistrict: function () {
                const command = new GetMaintanancePerDistrict();
                const values = {};
                values.attendanceWithSoluction = 1;
                values.startDate = this.$moment().startOf('month').format('DD/MM/YYYY');

                command.values = values;
                command.initializeLoading = false;
                command.onSuccess = this.processRequest;
                return command;
            },
            periodName: function () {
                switch (this.chartType) {
                    case 1:
                        return this.$t('DISTRICT');
                    case 2:
                        return this.$t('REGION');
                    default:
                        return '';
                }
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            _filters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getMaintenancePerDistrict, value);
                    }
                },
            },
            filters: {
                deep: true,
                handler: function (val) {
                    this.$emit('update:_filters', val);
                },
            },
            _chartData: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.totals = this.$lodash.get(value, 'totals', {});
                        this.loadData(this.$lodash.get(value, 'items', []));
                    }
                },
            },
            chartType: function () {
                this.getMaintenancePerDistrict.refreshData();
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$nextTick(this.$refs.chart.resize);
            },
            load: async function () {
                this.onResize();
                this.chart = this.$refs.chart.getChart();

                if (this.dashdata.filters.length !== 0) {
                    this.$util.loadCommandFilter(this.getMaintenancePerDistrict, this.dashdata.filters);
                    this.$refs.filter.refresh(true);
                }
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;
                // if the page is being used by the dashboard, update the filters
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getMaintenancePerDistrict);
                }

                this.loadData(items);
            },
            loadData: function (items) {
                this.formatToChart(items);
                this.loadChart();
            },
            formatToChart: function (data) {
                if (this.chartType === 2) {
                    data = this.computePerRegion(data);
                }

                this.reportData = [];
                this.categoryData = [];
                Object.keys(data).forEach((i) => {
                    const item = data[i];
                    item.name = this.chartType === 1 ? item.NOME_BAIRRO : item.NOME_REGIAO;
                    item.value = item.TOTAL_ATENDIMENTOS;

                    this.reportData.push(item);
                    this.categoryData.push(item.name);
                });
            },
            loadChart: function () {
                if (!this.$refs.chart) {
                    return;
                }

                this.$refs.chart.reset();
                this.setChartOption();
                this.addSerie();
                this.addTooltip();
                this.onResize();
            },
            setChartOption: function () {
                const chartOptions = {
                    maintainAspectRatio: false,
                    grid: {
                        containLabel: true,
                        top: '10%',
                        bottom: '10%',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.periodName,
                        data: this.categoryData,
                        axisLabel: {
                            rotate: -45,
                            interval: 0,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('AMOUNT'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                };

                this.$refs.chart.setOption(chartOptions);
            },
            addSerie: function () {
                this.$refs.chart.addSerie({
                    id: 1,
                    type: 'bar',
                    data: this.reportData,

                    animation: true,
                    itemStyle: {
                        color: () => this.$util.getRandomColor(),
                    },

                    label: {
                        show: true,
                        formatter: this.labelFormatter,
                        color: '#000000',
                        distance: 10,
                        position: 'top',
                        align: 'left',
                        verticalAlign: 'middle',
                        rotate: 90,
                    },
                });
            },
            addTooltip: function () {
                const tooltip = {
                    trigger: 'axis',
                    formatter: this.tooltipFormatter,
                    confine: true,
                };
                this.$refs.chart.setTooltip(tooltip);
            },
            tooltipFormatter: function (params) {
                const regionName = this.$lodash.get(params, '[0].data.NOME_REGIAO');
                const districtName = this.$lodash.get(params, '[0].data.NOME_BAIRRO');
                const attendanceTotal = this.$lodash.get(params, '[0].data.TOTAL_ATENDIMENTOS');

                let label = '';

                if (this.chartType === 1) {
                    label += `${this.$t('DISTRICT_NAME')}: ${districtName}<br />`;
                } else {
                    label += `${this.$t('REGION_NAME')}: ${regionName}<br />`;
                }

                label += `${this.$t('MAINTENANCE_TOTAL')}: ${this.$util.numberFormat(attendanceTotal)}<br />`;

                return label;
            },
            labelFormatter: function (params) {
                const attendance = Number(params.data.TOTAL_ATENDIMENTOS);
                const percentage = (attendance / this.totals.attendances) * 100;
                return `${this.$util.numberFormat(percentage)} % (${this.$util.numberFormat(attendance)})`;
            },
            processRequest: function (resolve) {
                this.filters = this.$util.getFiltersByCommand(this.getMaintenancePerDistrict);
                this.loadTotals(resolve);

                const chartData = {};
                chartData.items = this.$util.getArrayByNode(resolve, this.getMaintenancePerDistrict.node);
                chartData.totals = this.totals;
                this.updateChartData(chartData);
                return resolve;
            },
            loadTotals: function (resolve) {
                this.totals.attendances = Number(resolve.RAIZ.SOMA_TOTAL_ATENDIMENTOS);
                this.totals.assets = Number(resolve.RAIZ.SOMA_TOTAL_PONTOS);
                this.totals.lightSpots = Number(resolve.RAIZ.SOMA_TOTAL_LAMPADAS);
                if (this.totals.assets > 0) {
                    this.totals.percentage = (this.totals.attendances / this.totals.assets) * 100;
                } else {
                    this.totals.percentage = 0;
                }
            },
            computePerRegion: function (data) {
                const groupRegion = [];
                for (let i = 0; i < data.length; i++) {
                    const elem = data[i];
                    const region = groupRegion.find((e) => e.ID_REGIAO === elem.ID_REGIAO);

                    if (region) {
                        region.TOTAL_ATENDIMENTOS += Number(elem.TOTAL_ATENDIMENTOS);
                    } else {
                        groupRegion.push({
                            ID_REGIAO: elem.ID_REGIAO,
                            NOME_REGIAO: elem.NOME_REGIAO ? elem.NOME_REGIAO : this.$t('WITHOUT_REGION'),
                            TOTAL_ATENDIMENTOS: Number(elem.TOTAL_ATENDIMENTOS),
                        });
                    }
                }
                return groupRegion;
            },
            updateChartData: function (value) {
                this.$emit('update:_chart-data', value);
            },
        },
    };
</script>
