<template>
    <a-container ref='container' v-resize='onResize' class='container' style='width: 100%; height: 100%;'>
        <a-dynamic-command-filter
            ref='filter'
            :key='getTeam.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            :_fab='false'
            :_command='getTeam'
            @updateValues='updateDashData'
        />

        <a-row justify='center' style='width: 100%; height: 100%; position:relative;'>
            <a-btn
                v-if='!showMonthChart && tabMode'
                fab
                icon
                style='position:absolute; left:10px; top:10px; z-index:1;'
                @click='changeTab(true)'
            >
                <a-icon>mdi-arrow-right</a-icon>
            </a-btn>
            <a-btn
                v-if='showMonthChart && tabMode'
                fab
                icon
                style='position:absolute; left:10px; top:10px; z-index:1;'
                @click='changeTab(false)'
            >
                <a-icon>mdi-arrow-left</a-icon>
            </a-btn>

            <a-col v-show='!showMonthChart || !tabMode' eager :xl='!tabMode ? "6" : "12"' cols='12' class='fill-height'>
                <chart
                    v-show='!showMonthChart || !tabMode'
                    ref='teamChart'
                    :_options='teamChartOptions'
                />
            </a-col>
            <a-col v-show='showMonthChart || !tabMode ' eager :xl='!tabMode ? "6" : "12"' cols='12' class='fill-height'>
                <chart
                    v-show='showMonthChart || !tabMode'
                    ref='monthChart'
                    :_options='monthChartOptions'
                />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    import { GetMaintenanceByTeam, GetMonthlyMaintenanceByTeam } from '@/pages/maintenance/maintenanceByTeam/services/request';
    import consts from '@/components/charts/issues/issueMonitor/services/consts';

    export default {
        name: 'NewMaintenanceTeamChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * Prop to determine externally if charts' display is in tab mode
             * @type {Boolean}
             * @default {false}
             */
            _tabMode: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the MaintenanceByTeam page to fill the GetIssueDistricts fields
             * @type {Array}
             * @default {}
             */
            _teamFilters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,

                // Region Chart instance, used for the on() event
                teamChart: undefined,

                teamData: {},
                teamChartOptions: {
                    maintainAspectRatio: true,
                    tooltip: {
                        trigger: 'item',
                        confine: true,
                    },
                },
                selectedTeamId: null,

                monthData: {},
                barColor: undefined,
                monthChartOptions: {
                    maintainAspectRatio: true,
                    grid: {
                        containLabel: true,
                        left: '25%',
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('ATTENDANCES'),
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                        axisLabel: {
                            show: true,
                            formatter: '{value}',
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: `<b>${this.$t('DATE')}:</b> {b}<br /><b>${this.$t('ATTENDANCES')}:</b> {c}`,
                        confine: true,
                    },
                },

                showMonthChart: false,

                consts: consts,
                chartHeight: this._chartHeight || consts.DEFAULT_HEIGHT,
            };
        },
        computed: {
            getTeam: function () {
                const command = new GetMaintenanceByTeam();
                command.initializeLoading = false;
                return command;
            },
            getMonthlyTeam: function () {
                const command = new GetMonthlyMaintenanceByTeam();
                command.initializeLoading = false;
                return command;
            },
            tabMode: function () {
                this.$nextTick(this.onResize);
                return this._tabMode || this.$vuetify.breakpoint.lgAndDown;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            showMonthChart: function () {
                this.$nextTick(this.onResize);
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            _tabMode: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
            _activePage: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
            _teamFilters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getTeam, value);
                    }
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                if (this.$refs.teamChart) {
                    this.$refs.teamChart.resize({ height: this.chartHeight });
                }
                if (this.$refs.monthChart) {
                    this.$refs.monthChart.resize({ height: this._tabMode ? 600 : this.chartHeight });
                }
            },
            changeTab: function (value) {
                this.showMonthChart = value;
                this.$nextTick(this.onResize);
            },

            load: async function () {
                this.$nextTick(this.onResize);
                this.teamChart = this.$refs.teamChart.getChart();

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getTeam, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getTeam);
                this.showMonthChart = false;
                this.loadData(items);
            },

            loadData: async function (items) {
                try {
                    this.teamData = this.formatToChart(items);
                    this.loadTeamChart();
                    this.teamChart.on('click', { seriesIndex: 0 }, this.loadMonthChart);
                } catch (reject) {
                }
            },
            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].DESC_EQUIPE;
                    data[i].value = data[i].NR_ATENDIMENTO;
                });
                const formattedData = data;
                return formattedData;
            },
            loadTeamChart: function () {
                this.selectedTeamId = null;
                this.resetCharts();
                this.addTeamSerie();
            },
            resetCharts: function () {
                this.$refs.teamChart.reset();
                this.$refs.monthChart.reset();
            },
            addTeamSerie: function () {
                this.$refs.teamChart.addSerie({
                    id: 1,
                    type: 'pie',
                    data: this.teamData,
                    animation: true,
                    left: 'left',
                    top: 'middle',
                    radius: consts.DEFAULT_RADIUS,
                    width: '100%',
                    height: '100%',

                    label: {
                        formatter: (params) => {
                            let label = '';

                            label += `${params.name} `;
                            label += '\n';
                            label += `${this.$t('ATTENDANCES')}: ${params.value} - ${params.percent}%`;
                            label += '\n';
                            if (params.data.EMPRESA_USUARIA) {
                                label += `${this.$t('USER_COMPANY')}: ${params.data.EMPRESA_USUARIA}`;
                            }
                            if (params.data.LOTE) {
                                label += `${this.$t('BATCH')}: ${params.data.LOTE}`;
                            }
                            return label;
                        },
                    },

                    tooltip: {
                        formatter: (params) => {
                            let label = '';

                            label += `<b>${params.name} `;
                            label += '</b><br />';
                            label += `${this.$t('ATTENDANCES')}: ${params.value}`;
                            return label;
                        },
                    },

                    selectedMode: 'single',
                    selectedOffset: 25,
                });
            },
            loadMonthChart: async function (params) {
                this.barColor = params.color;
                this.selectedTeamId = this.selectedTeamId === params.data.ID_EQUIPE ? null : params.data.ID_EQUIPE;

                if (this.selectedTeamId && this.selectedTeamId !== '-1') {
                    this.changeTab(true);

                    const unformattedData = await this.loadMonths();
                    if (unformattedData[0].DIA) {
                        this.monthData = this.formatToDay(unformattedData);
                        this.addDaySerie();
                    } else {
                        this.monthData = this.formatToMonth(unformattedData);
                        this.addMonthSerie();
                        this.setChartOption();
                    }
                } else {
                    this.changeTab(false);

                    this.$refs.monthChart.reset();
                }
            },
            loadMonths: async function () {
                const filters = this.$util.getFiltersByCommand(this.getTeam);
                this.$util.loadCommandFilter(this.getMonthlyTeam, filters);
                this.getMonthlyTeam.fields.teamId.value = this.selectedTeamId;
                this.getMonthlyTeam.fields.serviceWithSolution.value = Number(this.getMonthlyTeam.fields.serviceWithSolution.value);

                try {
                    const resolve = await this.getMonthlyTeam.callCommand();
                    if (this.$_aura.getArray(() => resolve.RAIZ.DIAS.DIA).length !== 0) {
                        return this.$_aura.getArray(() => resolve.RAIZ.DIAS.DIA);
                    }
                    return this.$_aura.getArray(() => resolve.RAIZ.MESES.MES);
                } catch (reject) {
                    return undefined;
                }
            },
            formatToMonth: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].MES_ATENDIMENTO;
                    data[i].value = Number(data[i].NR_ATENDIMENTO);
                });
                const formattedData = data;
                return formattedData;
            },
            formatToDay: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].DIA_ATENDIMENTO;
                    data[i].value = Number(data[i].NR_ATENDIMENTO);
                });
                const formattedData = data;
                return formattedData;
            },
            setChartOption: function () {
                const dates = Object.values(this.monthData).map(obj => obj.name);
                const monthChartOptions = {
                    maintainAspectRatio: true,
                    grid: {
                        containLabel: true,
                        left: '5%',
                    },
                    xAxis: {
                        type: 'category',
                        name: this.$t('DATE'),
                        data: dates,
                        axisLabel: {
                            show: true,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('TOTAL_ASSETS'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: `<b>${this.$t('DATE')}:</b> {b}<br /><b>${this.$t('TOTAL_ASSETS')}:</b> {c}`,
                        confine: true,
                    },
                };

                this.$refs.monthChart.setOption(monthChartOptions);
            },
            addMonthSerie: function () {
                this.$refs.monthChart.reset();
                this.$refs.monthChart.addSerie({
                    id: 2,
                    type: 'line',
                    data: this.monthData,

                    animation: true,
                    left: 'bottom',
                    top: 'middle',
                    barWidth: '100%',
                    barHeight: '100%',
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    label: {
                        padding: [5, 5, 5, 50],
                        rotate: -30,
                    },
                });
            },
            addDaySerie: function () {
                this.$refs.monthChart.reset();
                this.$refs.monthChart.addSerie({
                    id: 2,
                    type: 'bar',
                    data: this.monthData,

                    animation: true,
                    left: 'bottom',
                    top: 'middle',
                    barWidth: '100%',
                    barHeight: '100%',
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    label: {
                        show: true,
                        position: 'bottom',
                        formatter: '{b}',
                        color: '#000000',
                        padding: [5, 5, 5, 50],
                        rotate: -45,
                    },
                });
            },
        },
    };
</script>

<style lang='scss' scoped>
    .fullscreen {
        height: 100%;
    }
</style>
