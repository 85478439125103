<template>
    <div>
        <a-row
            v-show="!_hideHeader"
            class="px-3 mb-2"
            :align='!command || !command.v2 ? "baseline" : undefined'
        >
            <span v-if="_title" class='title pr-3'>{{_title}}</span>

            <slot name='append-title' />

            <a-spacer />

            <slot name='prepend-filter' />
            
            <template v-if='!_filterDisabled && !$_aura.isEmpty(_command) && showInlineFilters && menuReady'>
                <a-command-filter
                    v-if="command.v2"
                    _mode='inline'
                    _group='main'
                    :_command.sync='command'
                    :_loading.sync='loading'
                    :_inline-fields-size='_inlineFieldsSize'
                    @update-items='updateValues'
                    @hook:mounted='emitFilterReady'
                />

                <a-dynamic-command-filter
                    v-else
                    exhibition-mode='inline'
                    filter-group='main'
                    :_command='_command'
                    :_inline-fields-size='_inlineFieldsSize'
                    @updateValues='updateValues'
                    @hook:mounted='emitFilterReady'
                />
            </template>

            <template v-if='!_filterDisabled && !$_aura.isEmpty(_command) && menuReady'>

                <a-command-filter                        
                    v-if="command.v2"
                    ref='filter'
                    _mode='sidebar'
                    :_group='!showInlineFilters ? "all" : "secondary"'
                    :_command.sync='command'
                    :_loading.sync='loading'
                    :_inline-fields-size='_inlineFieldsSize'
                    @update-items='updateValues'
                />

                <a-dynamic-command-filter
                    v-else
                    ref='filter'
                    :key='_command.name'
                    exhibition-mode='sidebar'
                    class='pivot-filter'
                    :filter-group='!showInlineFilters ? "all" : "secondary"'
                    :_fab='false'
                    :_command='_command'
                    :_inline-fields-size='_inlineFieldsSize'
                    @loading='loading = $event'
                    @updateValues='updateValues'
                />
            </template>
            
            <a-data-table-menu
                v-model="showMenu"
                :_items='itemsProvider'
                :_export-options='_exportOptions'
                :_headers='headers'
                :_extended-headers.sync='_extendedHeaders'
                :_visible-headers.sync='visibleHeaders'
                :_command.sync='command'
                :_disable-options='_disableOptions'
                :_advanced-filters.sync='advancedFilters'
                :_preference-key='_preferenceKey'
                :_disable-default-view-initial-loading='_disableDefaultViewInitialLoading'
                v-bind="_menuProps"
                @click:toggle-table-reports='toggleTableReports'
                @reload='refreshData'
                @ready='menuReady = true'
                @data-table-view-changed='emitViewChangedEvent'
            >
            
                <template #append-list>
                    
                    <slot name='menu-options' />

                    <a-text-field
                        v-if="isMobile"
                        v-model='searchLazy'
                        class='ml-1 my-1'
                        flat
                        hide-no-data
                        hide-details
                        single-line
                        outlined
                        dense
                        :label='$t("SEARCH")'
                        style='max-width: 300px'
                    >
                        <template v-slot:append>
                            <a-icon small search class='pa-1' />
                        </template>
                    </a-text-field>
                </template>
            </a-data-table-menu>
            
        </a-row>

        <slot name='info-header' />

        <v-card flat class='pa-0'>
            <v-card-text class='overflow-hidden pa-0' style='position: relative;'>
                <v-data-table
                    v-show='tableMode'
                    :search='searchComp'
                    :items='limitedItems'
                    :headers='visibleAndExtendedHeaders'
                    v-bind='attrsComp'
                    v-model='selectedItems'
                    :item-key='itemKey'
                    :hide-default-footer='_hideDefaultFooter'
                    :options.sync='optionsSync'
                    :class='{"table-striped" : _tableStriped && !virtualScrollActive}'
                    :server-items-length='_serverItemsLength || serverItemsLength'
                    :loading='loading'
                    :disable-sort='disableSort'
                    :style='tableStyle'
                    :dense='dense'
                    v-scroll.self="onScroll"
                    v-on='$listeners'
                    @pagination='pagination'
                    @click:row='clickRow'
                >
                    
                    <template v-if='!isSingleSelect' v-slot:[`header.data-table-select`]='{ props }'>
                        <a-simple-checkbox
                            v-bind='props'
                            @click='selectedItems.length !== itemProviderLength(itemsProvider) ? selectedItems =  itemProviderFilter(itemsProvider) : selectedItems = []'
                        />
                    </template>

                    <template
                        v-if="startHeight && this.virtualScrollActive"
                        v-slot:[`body.prepend`]
                        
                    >
                        <tr class="virtual-scroll-spacer mono-grey-10">
                            <td
                                :colspan="headers.length"
                                :style="'padding-top:'+startHeight+'px'"
                            >
                            </td>
                        </tr>
                    </template>
                    
                    <template
                        v-if="endHeight  && this.virtualScrollActive"
                        v-slot:[`body.append`]
                    >
                        <tr class="virtual-scroll-spacer mono-grey-10">
                            <td
                                :colspan="headers.length"
                                :style="'padding-top:'+endHeight+'px'"
                            >
                            </td>
                        </tr>
                    </template>
                    
                    <slot v-for='slot in Object.keys($slots)' :slot='slot' :name='slot' />
                    
                    <template v-if='Object.keys($attrs).includes("single-select") && $attrs["single-select"] !== false' v-slot:[`item.data-table-select`]='{select, isSelected}'>
                        <a-icon
                            select
                            color='undefined'
                            :radio-marked='isSelected'
                            :_tooltip='false'
                            @click='select(!isSelected)'
                        />
                    </template>
                    
                    <template v-for='slot in Object.keys($scopedSlots)' :slot='slot' slot-scope='scope'>
                        <slot :name='slot' v-bind='scope' />
                    </template>

                    <template v-for='header in headers.filter(h => !!h.monetary)' v-slot:[`item.${header.value}`]='{ item }'>
                        {{ $util.asMonetary(Number(item[header.value]) || 0, header.precision !== null && header.precision !== undefined ? header.precision : 2, header.hideGroupingSymbol || false) }}
                    </template>

                    <template v-slot:no-data>
                        <template v-if='Object.keys($slots).indexOf("no-data") >= 0'>
                            <slot name='no-data' />
                        </template>
                        <template v-else>
                            <a-list-item v-if='!$_aura.isEmpty(_command)'>
                                <a-list-item-title>
                                    {{ $t('SEARCH_DATA_TABLE_1') }} <a @click='$refs.filter.refresh(true)'>{{ $t('SEARCH_DATA_TABLE_2') }}</a> {{ $t('SEARCH_DATA_TABLE_3') }}
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item v-else>
                                <a-list-item-title>{{ $t('SEARCH_TO_FIND_REGISTERS')}}</a-list-item-title>
                            </a-list-item>
                        </template>
                    </template>
                    
                    <template v-if='Object.keys($slots).indexOf("top") < 0 && !_hideHeader' v-slot:top>
                        <a-banner single-line sticky class='mb-5'>
                            <a-row dense>
                                <a-col v-if='selectedCounterActive' cols='12' sm='auto'>
                                    <span>
                                        {{`${$t('NUMBER_OF_SELECTED_REGISTERS')}: ${selectedItems.length}`}}
                                    </span>
                                </a-col>
                            </a-row>
                            <slot name='prepend-search' />                            
                        </a-banner>
                    </template>

                </v-data-table>

                <a-card v-if='_command && !tableMode' flat style='width: 100%'>
                    <component
                        :is='$_aura.pivot.component'
                        :_title='_title'
                        :_preference-key='_preferenceKey'
                        :_command-name='_command.name'
                        :_items='itemsProvider'
                    />
                </a-card>

                <a-row justify='end' align='center' v-if="!_hideFooter" >
                    <a-col class='grow'>
                        <slot name='prepend-pagination' />
                        <div>
                            <a-text-field 
                                v-if='Object.keys($slots).indexOf("prepend-pagination") < 0 && !isMobile && !_hideSearchBar && !_paginate && !virtualScrollActive'
                                v-model='searchLazy'
                                flat
                                hide-no-data
                                hide-details
                                single-line
                                outlined
                                dense
                                append-icon='mdi-filter-outline'
                                style='max-width: 300px'
                                :label='$t("FILTER_RESULTS")'
                            >
                            </a-text-field>
                            <a-spacer />
                        </div>
                    </a-col>

                    <template v-if='tableMode && _hideDefaultFooter && !_hideFooter'>
                        <a-col cols='12' sm='auto'>
                            <a-combobox
                                    ref='itemsPerPageCombobox'
                                    v-model='optionsSync.itemsPerPage'
                                    :items='rowsPerPageOptions'
                                    :filter='rowsPerPageFilter'
                                    hide-selected
                                    hide-details
                                    dense
                                    :label='$t("ROWS_PER_PAGE")'
                                    :disabled='loading'
                                    class='items-per-page-width'>
                                <template v-slot:selection='{ item }'>
                                    <span class='pr-2'>{{Number(item) === -1 ? $t('ALL') : item}}</span>
                                </template>
                                <template v-slot:item='{ item }'>
                                    <a-list-item-title>
                                        {{Number(item) === -1 ? $t('ALL') : item}}
                                    </a-list-item-title>
                                </template>
                            </a-combobox>
                        </a-col>
                        
                        <a-col v-if='paginationInfo && paginationInfo.itemsLength' cols='12' sm='auto'>
                            <a-row justify='end' no-gutters>
                                <a-col cols='auto'>
                                    <span v-if='!_paginate'>{{$t('PAGE_OF_PAGE_COUNT', { page: paginationInfo.pageStart + 1, num_items: virtualScrollActive ? itemsProvider.length : paginationInfo.pageStop, page_count: virtualScrollActive ? itemsProvider.length : paginationInfo.itemsLength })}}</span>
                                    <span v-else>{{$t('PAGE')}}: {{optionsSync.page}}</span>
                                </a-col>
                            </a-row>
                        </a-col>

                        <a-col cols='12' sm='auto'>
                            <a-row no-gutters justify='end'>
                                
                                <a-col cols='auto'>
                                    <a-btn
                                        previous
                                        icon
                                        :_x-small='true'
                                        color-icon='undefined'
                                        color='undefined'
                                        :disabled='disablePrevious'
                                        @click='optionsSync.page--'
                                    />
                                </a-col>
                                
                                <a-col cols='auto'>
                                    <a-btn
                                        next
                                        icon
                                        :_x-small='true'
                                        color-icon='undefined'
                                        color='undefined'
                                        :disabled='disableNext'
                                        @click='optionsSync.page++'
                                    />
                                </a-col>

                            </a-row>
                        </a-col>
                    </template>

                </a-row>

            </v-card-text>

        </v-card>
    </div>
</template>

<script>
    import tableFunctions from '../../util/mixins/table-functions';

    export default {
        name: 'ADataTable',
        mixins: [tableFunctions],
        data: function () {
            return {
                start: 0,
                timeout: null,
                rowHeight: 48,
                scrollTop: 0,
                scroolPadding: 10,
                virtualScrollHeight: 500,
                perPage: Math.ceil( 500 / 24),
                menuReady: false,
            }
        },
        computed: {
            startIndex() {
                const startNode = Math.floor(this.scrollTop / this.rowHeight) - this.scroolPadding;
                return Math.max(0, startNode);
            },
            visibleItemsCount() {
                const count = Math.ceil(this.virtualScrollHeight / this.rowHeight) + 2 * this.scroolPadding;
                return Math.min(this.itemsProvider.length - this.startIndex, count);
            },
            orderedItems() {
                if (!this.virtualScrollActive) {
                    return this.itemsProvider;
                }

                const sortBy = this.optionsSync.sortBy.map((key) => {
                    return (item) => {
                        const value = item[key]
                        
                        const date = this.$moment(value, 'DD/MM/YYYY');
                        const dateTime = this.$moment(value, 'DD/MM/YYYY HH:mm:ss');

                        if (date.isValid()) {
                            return date.toDate()
                        }

                        if (dateTime.isValid()) {
                            return dateTime.toDate()
                        }

                        return value;
                    }
                })

                return this.$lodash.orderBy(
                    this.itemsProvider,
                    sortBy,
                    this.optionsSync.sortDesc.map(s => s ? 'desc' : 'asc')
                )
            },
            limitedItems() {
                if (!this.virtualScrollActive) {
                    return this.itemsProvider;
                }

                return this.orderedItems.slice(
                    this.startIndex,
                    this.startIndex + this.visibleItemsCount
                );
            },
            startHeight() {
                return Math.max(0, this.startIndex * this.rowHeight);
            },
            endHeight() {
                const lastIndex = this.itemsProvider.length - (this.startIndex + this.visibleItemsCount);
                
                if (lastIndex >= this.itemsProvider.length) {
                    return 0
                }

                return Math.max(0, this.rowHeight * (this.itemsProvider.length - (this.startIndex + this.visibleItemsCount)));
            },
            virtualScrollActive: function () {
                return this.optionsSync.itemsPerPage == -1 && !this.searchLazy && this.itemsProvider.length > 100;
            },
            tableStyle: function () {
                if (!this.virtualScrollActive) {
                    return undefined
                }

                return {
                    height: `${this.virtualScrollHeight}px`,
                    overflow: 'auto'
                }
            },
            selectedCounterActive: function () {
                const showSelect = Object.keys(this.$attrs).includes("show-select") && this.$attrs["show-select"] !== false;
                const singleSelect = Object.keys(this.$attrs).includes("single-select") && this.$attrs["single-select"] !== false;
                return showSelect && !singleSelect && this.showSelectedItemsCounter && this.selectedItems.length;
            },
            disableSort: function () {
                if (Object.keys(this.$attrs).includes("disable-sort")) {
                    return this.$attrs["disable-sort"] !== false;
                }
                return this._paginate && this.optionsSync.itemsPerPage > -1;
            },
            isSingleSelect: function () {
                return Object.keys(this.$attrs).includes("single-select") && this.$attrs["single-select"] !== false;
            },
            visibleAndExtendedHeaders: function () {
                return this.visibleHeaders.concat(this._extendedHeaders);
            },
        },
        methods: {
            emitViewChangedEvent: function (view) {
                this.$emit('data-table-view-changed', view);
            },
            onScroll(e) {
                // debounce if scrolling fast
                this.timeout && clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    this.scrollTop = e.target.scrollTop
                }, 20);
            },
            itemProviderLength (itemProvider) {
                const hasSelectable = itemProvider.some(item => 'isSelectable' in item);
                if(!hasSelectable) {
                    return itemProvider.length;
                }
                return itemProvider.filter(item => (item.isSelectable || item.isSelectable === undefined)).length;
            },
            itemProviderFilter (itemProvider) {
                const hasSelectable = itemProvider.some(item => 'isSelectable' in item);
                if(!hasSelectable) {
                    return itemProvider;
                }
                return itemProvider.filter(item => (item.isSelectable || item.isSelectable === undefined));
            },
            emitFilterReady: function () {
                this.$emit('filter-ready');
            }
        },
    };
</script>

<style scoped lang='stylus'>
    >>>.v-toolbar--dense
        .v-input__control
            min-height 38px !important
    .v-banner >>>.v-banner__wrapper
        padding 0
        padding-left 8px

    .v-banner >>>.v-banner__actions
        margin-left 0

    .v-banner >>>.v-banner__content
        overflow inherit

    .v-banner >>>.v-banner__text
        width 100%

    >>>.table-striped.theme--light tbody tr:nth-of-type(2n+1) td
        background-color rgba(180, 180, 180, 0.1)

    >>>.table-striped.theme--dark tbody tr:nth-of-type(2n+2) td
         background-color rgba(255, 255, 255, 0.05)

    >>>table th + th
        border-left 1px solid #dddddd
    >>>table td + td
        border-left 1px solid #dddddd

    .items-per-page-width
        max-width 180px
</style>
