<template>
    <a-container
        ref='container'
        v-resize='onResize'
        class='container'
        style='width: 100%; height: 100%;'
    >
        <a-dynamic-command-filter
            ref='filter'
            :key='getMaintenanceRegions.name'
            :_sidebar-props='{ _hideActions: true }'
            :_loading.sync='loading'
            filter-group='all'
            exhibition-mode='sidebar'
            :_fab='false'
            class='pivot-filter'
            :_command='getMaintenanceRegions'
            @updateValues='updateDashData'
        />

        <a-row
            justify='center'
            no-gutters
            style='width: 100%; height: 100%; position:relative;'
        >
            <a-btn
                v-if='!showDistrictChart && tabMode'
                fab
                icon
                style='position:absolute; left:10px; top:10px; z-index:2;'
                @click='changeTab(true)'
            >
                <a-icon>mdi-arrow-right</a-icon>
            </a-btn>
            <a-btn
                v-if='showDistrictChart && tabMode'
                fab
                icon
                style='position:absolute; left:10px; top:10px; z-index:2;'
                @click='changeTab(false)'
            >
                <a-icon>mdi-arrow-left</a-icon>
            </a-btn>

            <a-col :xl='!tabMode ? "6" : "12"' cols='12'>
                <chart
                    v-show='!showDistrictChart || !tabMode'
                    ref='regionChart'
                    :class='{"fullscreen": !_tabMode}'
                    :_options='regionChartOptions'
                />
            </a-col>
            <a-col :xl='!tabMode ? "6" : "12"' cols='12'>
                <chart
                    v-show='showDistrictChart || !tabMode'
                    ref='districtChart'
                    :_options='districtChartOptions'
                />
            </a-col>
        </a-row>
    </a-container>
</template>

<script>
    import consts from './services/consts';
    import { GetMaintenanceRegions, GetMaintenanceDistricts } from './services/request';

    export default {
        name: 'MaintenanceByRegionAndDistrictMonitor',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Parameter to determine the chart's height
             * @type {[String, Number]}
             * @default {undefined}
             */
            _chartHeight: {
                type: [String, Number],
                default: undefined,
            },
            /**
             * Prop to determine externally if charts' display is in tab mode
             * @type {Boolean}
             * @default {false}
             */
            _tabMode: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the MaintenanceMonitor page to fill the GetMaintenanceDistricts fields
             * @type {Array}
             * @default {}
             */
            _maintenanceMonitorFilters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
            /**
             * returns the browser window width
             * @type {Function}
             * @default {null}
             */
            _windowWidth: {
                type: Function,
                default: () => 1080,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,

                // Region Chart instance, used for the on() event
                regionChart: undefined,

                regionData: {},
                regionChartOptions: {
                    maintainAspectRatio: true,
                    tooltip: {
                        trigger: 'item',
                        confine: true,
                    },
                },
                selectedRegionId: null,

                districtData: {},
                barColor: undefined,
                districtChartOptions: {
                    maintainAspectRatio: true,
                    grid: {
                        containLabel: true,
                        left: '25%',
                    },
                    yAxis: {
                        type: 'category',
                        name: this.$t('DISTRICTS'),
                    },
                    xAxis: {
                        type: 'value',
                        name: this.$t('ATTENDANCES'),
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: `<b>${this.$t('DISTRICT')}:</b> {b}<br /><b>${this.$t('ATTENDANCES')}:</b> {c}`,
                        confine: true,
                    },
                },

                showDistrictChart: false,

                consts: consts,
                chartHeight: this._chartHeight || consts.DEFAULT_HEIGHT,
                fluidChartHeight: 750,
            };
        },
        computed: {
            getMaintenanceRegions: function () {
                const command = new GetMaintenanceRegions();
                command.initializeLoading = false;
                return command;
            },
            getMaintenanceDistricts: function () {
                const command = new GetMaintenanceDistricts();
                command.initializeLoading = false;
                return command;
            },
            tabMode: function () {
                this.$nextTick(this.onResize);
                return this._tabMode || this.$vuetify.breakpoint.lgAndDown;
            },

            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            dashdata: {
                deep: true,
                handler: function (value) {
                    this.$emit('update-dashdata', value);
                },
            },
            _chartHeight: {
                handler: function (value) {
                    this.chartHeight = value;
                    this.$nextTick(this.onResize);
                },
            },
            fluidChartHeight: {
                handler: function (value) {
                    if (value < 750) {
                        this.fluidChartHeight = 750;
                    }
                    this.$nextTick(this.onResize);
                },
            },
            _tabMode: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
            _activePage: {
                handler: function () {
                    this.$nextTick(this.onResize);
                },
            },
            _maintenanceMonitorFilters: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.$util.loadCommandFilter(this.getMaintenanceDistricts, value);
                    }
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                this.$refs.regionChart.resize({ height: this.chartHeight });
                this.$refs.districtChart.resize({ height: this._tabMode ? this.chartHeight : this.fluidChartHeight });
            },
            changeTab: function (value) {
                this.showDistrictChart = value;
                this.$nextTick(this.onResize);
            },

            load: async function () {
                this.$nextTick(this.onResize);
                this.regionChart = this.$refs.regionChart.getChart();

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getMaintenanceRegions, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },

            updateDashData: function (items) {
                this.emptyData = !items.length;
                this.dashdata.filters = this.$util.getFiltersByCommand(this.getMaintenanceRegions);
                this.loadData(items);
            },

            loadData: async function (items) {
                this.regionData = this.formatToChart(items);
                this.loadRegionChart();
                this.regionChart.on('click', { seriesIndex: 0 }, this.loadDistrictChart);
            },
            formatToChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].NOME_REGIAO;
                    data[i].value = data[i].TOTAL;
                });
                const formattedData = data;
                return formattedData;
            },
            loadRegionChart: function () {
                this.selectedRegionId = null;
                // reset both region and district charts, so the district chart
                // won't have outdated data
                this.resetCharts();
                this.addRegionSerie();
            },
            resetCharts: function () {
                this.$refs.regionChart.reset();
                this.$refs.districtChart.reset();
            },
            addRegionSerie: function () {
                this.$refs.regionChart.addSerie({
                    id: 1,
                    type: 'pie',
                    data: this.regionData,

                    animation: true,
                    left: 'left',
                    top: 'middle',
                    radius: consts.DEFAULT_RADIUS,
                    width: '100%',
                    height: '100%',

                    label: {
                        formatter: consts.DEFAULT_LABEL,
                    },

                    tooltip: {
                        formatter: consts.DEFAULT_TOOLTIP,
                    },

                    selectedMode: 'single',
                    selectedOffset: 25,
                });
            },

            loadDistrictChart: async function (params) {
                this.barColor = params.color;
                this.selectedRegionId = this.selectedRegionId === params.data.ID_REGIAO ? null : params.data.ID_REGIAO;

                if (this.selectedRegionId && this.selectedRegionId !== '-1') {
                    this.changeTab(true);

                    const unformattedData = await this.loadDistricts();
                    this.districtData = this.formatToDistrictChart(unformattedData);
                    this.addDistrictSerie();
                    this.fluidChartHeight = this.districtData.length * 10.5 + 180;
                } else {
                    this.changeTab(false);

                    this.$refs.districtChart.reset();
                }
            },
            loadDistricts: async function () {
                this.getMaintenanceDistricts.fields.regionId.value = this.selectedRegionId;

                try {
                    const resolve = await this.getMaintenanceDistricts.callCommand();
                    return this.$_aura.getArray(() => resolve.RAIZ.BAIRROS.BAIRRO);
                } catch (reject) {
                    return undefined;
                }
            },
            formatToDistrictChart: function (data) {
                Object.keys(data).forEach((i) => {
                    data[i].name = data[i].NOME_BAIRRO;
                    data[i].value = data[i].TOTAL;
                });
                const formattedData = data;
                return formattedData;
            },
            addDistrictSerie: function () {
                this.$refs.districtChart.reset();
                this.$refs.districtChart.addSerie({
                    id: 2,
                    type: 'bar',
                    data: this.districtData,

                    animation: true,
                    left: 'right',
                    top: 'middle',
                    barWidth: '100%',
                    barHeight: '100%',
                    itemStyle: {
                        color: this.barColor,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    label: {
                        show: true,
                        position: 'left',
                        formatter: '{b}',
                        color: '#000000',
                        padding: [3, 4, 5, 6],
                    },
                });
            },
        },
    };
</script>

<style lang='scss' scoped>
    .fullscreen {
        height: 100%;
    }
</style>
