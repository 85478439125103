<template>
    <a-container
        ref='container'
        v-resize='onResize'
        class='container'
        style='height: 100%;'
    >
        <a-col>
            <a-tabs v-model='activeTab' class='mt-0'>
                <a-tab>{{$t("DETAILED_REPORT")}}</a-tab>
                <a-tab>{{$t("STACKED_BAR_CHART")}}</a-tab>
                <a-tab>{{$t("BAR_CHART")}}</a-tab>
            </a-tabs>
        </a-col>
        <a-dynamic-command-filter
            ref='filter'
            :key='getIssuesByDeadline.name'
            filter-group='all'
            exhibition-mode='sidebar'
            class='pivot-filter'
            :_command='getIssuesByDeadline'
            :_fab='false'
            @updateValues='updateDashData'
        />
        <a-data-table
            v-if='activeTab === 0'
            ref='table'
            :headers='detailedReport.headers'
            :_command='getIssuesByDeadline'
            :items='detailedReport.items'
            _paginate
            _hide-header
        >
            <template v-slot:info-header>
                <a-data-table-info-card :_items='detailedReport.totals' _show-highlighted />
            </template>
            <template v-slot:item.deadline='{ value }'>
                {{$util.formatDateTimeWithUserLocale(value)}}
            </template>
            <template v-slot:item.date='{ value }'>
                {{$util.formatDateTimeWithUserLocale(value)}}
            </template>
        </a-data-table>
        <chart
            v-if='activeTab !== 0'
            ref='chart'
            :_options='chartOptions'
            :_colors='chartColors'
            style='height: 80%;'
        />
    </a-container>
</template>

<script>
    import { GetIssuesByDeadline } from '@/pages/maintenance/services/request.ts';

    export default {
        name: 'MaintenancesRegardingDeadlineChart',
        props: {
            /**
             * Prop to show if page is currently active
             * @type {Boolean}
             * @default {false}
            */
            _activePage: {
                type: Boolean,
                default: false,
            },
            /**
             * Command filter values sent from the Dashboard
             * @type {Object}
             * @default {{ filters: [], }}
             */
            _dashdata: {
                type: Object,
                default: () => ({
                    filters: [],
                }),
            },
            /**
             * Override filters command
             * @type {Array}
             * @default {[]}
             */
            _filters: {
                type: Array,
                default: () => ([]),
            },
            /**
             * Prop to determine if the chart is being used from the dashboard
             * Set to false if the component is being used outside of it
             * @type {Boolean}
             * @default {true}
             */
            _dashboardMode: {
                type: Boolean,
                default: true,
            },
            /**
             * sync with parent loading
             * @type {Boolean}
             * @default {false}
             */
            _loading: {
                type: Boolean,
                default: false,
            },
            /**
             * sync with parent empty-data
             * @type {Boolean}
             * @default {false}
             */
            _emptyData: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                dashdata: this._dashdata,
                chartOptions: {
                    grid: {
                        containLabel: true,
                        left: '5%',
                    },
                    legend: {
                        orient: 'horizontal',
                        right: 50,
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: this.tooltipFormatter,
                        confine: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: [],
                    },
                    yAxis: {
                        type: 'value',
                        name: this.$t('ATTENDANCES'),
                        axisLabel: {
                            formatter: (value) => this.$util.numberFormat(value, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                        },
                    },
                },
                detailedReport: {
                    headers: [
                        {
                            text: this.$t('MAINTENANCE_ID'),
                            value: 'ID_ATENDIMENTO_PS',
                        },
                        {
                            text: this.$t('MAINTENANCE_DEADLINE'),
                            value: 'deadline',
                            sort: this.$util.dateTimeSort,
                        },
                        {
                            text: this.$t('MAINTENANCE_DATE'),
                            value: 'date',
                            sort: this.$util.dateTimeSort,
                        },
                        {
                            text: this.$t('EXECUTION_FORECAST_HOURS'),
                            value: 'PREVISAO_EXECUCAO',
                        },
                        {
                            text: this.$t('DEADLINE'),
                            value: 'NO_PRAZO',
                        },
                        {
                            text: this.$t('ISSUE_ORIGIN'),
                            value: 'ORIGEM_OCORRENCIA',
                        },
                    ],
                    totals: [],
                    items: [],
                },
                chartColors: [this.$vuetify.theme.currentTheme.green, this.$vuetify.theme.currentTheme.red, this.$vuetify.theme.currentTheme['exati-blue-main']],
                reportData: [],
                formattedData: [],
                activeTab: 0,
            };
        },
        computed: {
            getIssuesByDeadline: function () {
                const command = new GetIssuesByDeadline();
                command.initializeLoading = false;
                command.node = 'ATENDIMENTOS.ATENDIMENTO';
                command.requireRequest = fields => !!fields.worksiteId.value || this.$t('MANDATORY_FIELD_ENTITY', { entity: this.$t('WORKSITE') });
                command.fields.initialYear.component = 'ASelect';
                command.fields.finalYear.component = 'ASelect';
                command.fields.initialYear.props.items = this.getYears();
                command.fields.initialYear.props.label = this.$t('STARTING_YEAR');
                command.fields.initialYear.props.clearable = false;
                command.fields.finalYear.props.items = this.getYears();
                command.fields.finalYear.props.label = this.$t('ENDING_YEAR');
                command.fields.finalYear.props.clearable = false;
                const values = {};
                command.values = values;
                command.fields.detailed.value = 1;
                command.fields.groupingType.value = null;
                command.onSuccess = this.onSuccess;
                return command;
            },
            isStackChart: function () {
                return this.activeTab === 1;
            },
            isWeekMonthChart: function () {
                return this.getIssuesByDeadline.fields.groupingType.value === 1 || this.getIssuesByDeadline.fields.groupingType.value === 2;
            },
            loading: {
                get: function () {
                    return this._loading;
                },
                set: function (value) {
                    this.$emit('update:_loading', value);
                },
            },
            emptyData: {
                get: function () {
                    return this._emptyData;
                },
                set: function (value) {
                    this.$emit('update:_emptyData', value);
                },
            },
        },
        watch: {
            _dashdata: {
                deep: true,
                handler: function (val, oldVal) {
                    if (!this.$lodash.isEqual(val, oldVal)) {
                        this.dashdata = val;
                    }
                },
            },
            dashdata: {
                deep: true,
                handler: function (val) {
                    this.$emit('update-dashdata', val);
                },
            },
            _activePage: {
                handler: function () {
                    this.onResize();
                },
            },
            activeTab: {
                handler: function (val) {
                    if (val === 0) {
                        this.getIssuesByDeadline.fields.detailed.value = 1;
                        this.getIssuesByDeadline.fields.groupingType.value = null;
                        this.getIssuesByDeadline.onSuccess = this.onSuccess;
                        return;
                    }
                    this.getIssuesByDeadline.fields.detailed.value = 0;
                    this.getIssuesByDeadline.fields.groupingType.value = 1;
                    this.getIssuesByDeadline.onSuccess = (resolve) => resolve;
                    this.loadChart();
                },
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            onResize: function () {
                if (this.activeTab === 0) {
                    return;
                }
                this.$refs.chart?.resize({ height: this.chartHeight });
            },
            load: async function () {
                this.$nextTick(this.onResize);

                if (this.dashdata.filters.length === 0) {
                    return;
                }
                this.$util.loadCommandFilter(this.getIssuesByDeadline, this.dashdata.filters);
                this.$refs.filter.refresh(true);
            },
            updateDashData: function (items) {
                this.emptyData = !items.length;
                if (this._dashboardMode) {
                    this.dashdata.filters = this.$util.getFiltersByCommand(this.getIssuesByDeadline);
                }
                this.loadData(items);
            },
            loadData: async function (items) {
                this.reportData = items;
                this.loadChart();
            },
            onSuccess: function (resolve) {
                const data = this.$util.getArrayByNode(resolve, this.getIssuesByDeadline.node);

                data.forEach(item => {
                    item.deadline = item.DATA_LIMITE_ATENDIMENTO ? `${item.DATA_LIMITE_ATENDIMENTO} ${item.HORA_LIMITE_ATENDIMENTO}` : '';
                    item.date = `${item.DATA_ATENDIMENTO} ${item.HORA_ATENDIMENTO}`;
                });

                this.detailedReport.items = data;

                const totals = this.$util.getObjectByNode(resolve, 'TOTAIS');

                this.detailedReport.totals = [
                    {
                        text: this.$t('TOTAL_OUT_OF_TIME'),
                        value: totals.FORA_PRAZO,
                        highlighted: true,
                    },
                    {
                        text: this.$t('TOTAL_ON_TIME'),
                        value: totals.DENTRO_PRAZO,
                        highlighted: true,
                    },
                    {
                        text: this.$t('TOTAL_SEPARATE'),
                        value: totals.AVULSO,
                        highlighted: true,
                    },
                ];

                return resolve;
            },
            loadChart: function () {
                if (this.activeTab === 0) {
                    return;
                }
                this.$refs.chart?.reset();
                this.formatToChart();
                this.setXAxis();
                this.insertIntoChart();
            },
            setXAxis: function () {
                const { categoryData } = this;
                let xAxis;
                if (!this.isWeekMonthChart) {
                    xAxis = {
                        type: 'category',
                        axisTick: { show: false },
                        axisLabel: {
                            rotate: -30,
                            interval: 0,
                        },
                        data: categoryData,
                    };
                } else {
                    xAxis = {
                        type: 'category',
                        data: categoryData,
                    };
                }

                this.chartOptions.xAxis = xAxis;
                // this.$refs.chart.setProperty('xAxis', xAxis);
            },
            insertIntoChart: function () {
                const data = this.formattedData;

                const inTime = {
                    id: 1,
                    type: 'bar',
                    animation: true,
                    barGap: 0,
                    name: this.$t('IN_TIME'),
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    data: data.inDeadline,
                };
                const inDeadline = {
                    id: 2,
                    type: 'bar',
                    animation: true,
                    name: this.$t('OUT_OF_TIME'),
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    data: data.notInDeadline,
                };
                const noIssue = {
                    id: 3,
                    type: 'bar',
                    animation: true,
                    name: this.$t('NO_ISSUE'),
                    itemStyle: {
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                        shadowBlur: 10,
                    },

                    data: data.noIssue,
                };

                if (this.isStackChart) {
                    inTime.stack = 'chart';
                    inDeadline.stack = 'chart';
                    noIssue.stack = 'chart';
                }
                this.$refs.chart?.addSerie(inTime);
                this.$refs.chart?.addSerie(inDeadline);
                this.$refs.chart?.addSerie(noIssue);
            },
            formatToChart: function () {
                const data = this.reportData;
                const xAxisLabel = [];

                const inDeadline = [];
                const notInDeadline = [];
                const noIssue = [];

                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    const label = item.ATENDIMENTO;
                    xAxisLabel.push(label);

                    inDeadline.push({
                        ...item,
                        value: item.ATENDIMENTO_DENTRO_PRAZO,
                        type: 'inDeadline',
                    });
                    notInDeadline.push({
                        ...item,
                        value: item.ATENDIMENTO_FORA_PRAZO,
                        type: 'notDeadline',
                    });
                    noIssue.push({
                        ...item,
                        value: item.ATENDIMENTO_SEM_OCORRENCIA,
                        type: 'noIssue',
                    });
                }

                this.formattedData = { inDeadline: inDeadline, notInDeadline: notInDeadline, noIssue: noIssue };
                this.categoryData = xAxisLabel;
            },
            loadDataFromFilters: function (filters) {
                this.$util.loadCommandFilter(this.getIssuesByDeadline, filters);
                this.$refs.filter.refresh(true);
            },
            getYears: function () {
                const years = [];
                const dateStart = this.$moment().subtract(7, 'years');
                const dateEnd = this.$moment();
                while (dateEnd.diff(dateStart, 'years') >= 0) {
                    years.push(dateStart.format('YYYY'));
                    dateStart.add(1, 'year');
                }
                return years;
            },
            tooltipFormatter: function (params) {
                const { data } = params;
                let label = '';
                if (this.isWeekMonthChart) {
                    label += `<b>${this.$t('PERIOD')}:</b> ${data.ATENDIMENTO}<br />`;
                } else {
                    label += `<b>${this.$t('ORIGIN_TYPE')}:</b> ${data.ATENDIMENTO}<br />`;
                }
                label += `<b>${this.$t('ATTENDANCES')} ${this.$t('IN_TIME').toLowerCase()}:</b> ${data.PORC_ATENDIMENTO_DENTRO_PRAZO}<br />`;
                label += `<b>${this.$t('ATTENDANCES')} ${this.$t('OUT_OF_TIME').toLowerCase()}:</b> ${data.PORC_ATENDIMENTO_FORA_PRAZO}<br />`;
                label += `<b>${this.$t('ATTENDANCES')} ${this.$t('NO_ISSUE').toLowerCase()}:</b> ${data.PORC_ATENDIMENTO_SEM_OCORRENCIA}<br />`;
                return label;
            },
        },
    };
</script>
