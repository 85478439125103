<template>
    <a-form @submit.prevent='submit'>
        <a-btn type='submit' class="d-none" />

        <a-row
            v-if='_mode === "inline"'
            justify='end'
            no-gutters
        >
            <template v-for='(param, index) in commandFields'>
                <a-col :key='index' class="px-2" v-bind="param.columnProps">
                    <component
                        :is='param.component'
                        v-model='param.value'
                        v-bind='param.props'
                        v-on="param.events"
                        :disabled='loading'
                        hide-details='auto'
                    />
                </a-col>

            </template>
        </a-row>

        <template v-else>

            <div class="d-flex" v-if="!_hideFilterAction">
                <a-badge                    
                    overlap
                    left
                    color='red'
                    :content='filtersCount'
                    :value='!!filtersCount'
                >
                    <a-btn
                        tile
                        small
                        depressed
                        filter
                        small-icon
                        color='exati-blue-main'
                        color-icon='white'
                        class="mr-2"
                        :loading='loading'
                        @click="drawer = true"
                    />
                </a-badge>
                   
                <a-btn
                    tile
                    small
                    depressed
                    search
                    small-icon
                    outlined
                    color='mono-grey-40'
                    :loading='loading'
                    @click="submit"
                />

            </div>

            <a-navigation-drawer
                v-model="drawer"
                width='500'
                fixed
                right
                temporary
                hide-overlay
                class='elevation-0'
                v-bind="_drawerProps"
            >
                <template #prepend>
                    <slot name="drawer-header" >

                        <a-card-title>
                            {{$t('FILTERS')}}
                            <a-spacer />
                            <a-btn
                                close
                                icon
                                :small-icon='false'
                                color-icon='mono-grey-40'
                                @click='drawer = false'
                            />
                        </a-card-title>

                    </slot>
                </template>

                <a-card-text class="pt-0">
                    <a-row>
                        <template v-for='(param, index) in commandFields'>
                            <a-col cols='12' :key='index'>
                                <component
                                    :is='param.component'
                                    v-model='param.value'
                                    v-bind='param.props'
                                    v-on="param.events"
                                    :disabled='loading'
                                    hide-details='auto'
                                />
                            </a-col>

                        </template>
                    </a-row>
                </a-card-text>


                <div slot='append' class="pa-6 d-flex justify-end">
                    <a-btn 
                        :loading='loading'
                        :disabled='loading'
                        class="mr-2"
                        outlined
                        color='mono-grey-40'
                        @click="clear"
                    >
                        {{$t('CLEAR')}}
                    </a-btn>
                    
                    <a-btn
                        color='primary'
                        :loading='loading'
                        :disabled='loading'
                        @click='submit'
                    >
                        {{$t('APPLY')}}
                    </a-btn>
                </div>

            </a-navigation-drawer>

        </template>

    </a-form>
</template>

<script>
    // version 2 of command filters api
    export default {
        name: 'ACommandFilter',
        props: {
            _command: {
                type: Object,
                required: true,
                default: null,
            },
            _mode: {
                type: String,
                default: 'sidebar'
            },
            _group: {
                type: String,
                default: 'all',
            },
            _inlineFieldsSize:{
                type: Number,
                default: 3
            },
            _drawerProps: {
                type: Object,
                default: () => ({})
            },
            _loading: {
                type: Boolean,
                default: null
            },
            _hideFilterAction: {
                type: Boolean,
                default: false
            },
            _drawer: {
                type: Boolean,
                default: null
            },
            _requestOnMounted: {
                type: Boolean,
                default: false
            },
        },
        data: function () {
            return {
                innerDrawer: false,
                innerLoading: false,
            };
        },
        computed: {
            command: {
                get: function () {
                    return this._command;
                },
                set: function (value) {
                    return this.$emit('update:_command', value);
                },
            },
            drawer: {
                get: function () {
                    if (this._drawer !== null) {
                        return this._drawer
                    }

                    return this.innerDrawer;
                },
                set: function (value) {
                    if (this._drawer !== null) {
                        this.$emit('update:_drawer', value)
                        return
                    }

                    this.innerDrawer = value;
                },
            },
            loading: {
                get: function () {
                    if (this._loading !== null) {
                        return this._loading
                    }

                    return this.innerLoading;
                },
                set: function (value) {
                    if (this._loading !== null) {
                        this.$emit('update:_loading', value)
                        return
                    }

                    this.innerLoading = value;
                },
            },
            commandFields: function () {
                if (!this.command || !this.command.params) {
                    return []
                }

                if(this._group === 'all') {
                    return Object.values(this.command.params)
                        .filter((f) => f.component)
                }

                if(this._group === 'main') {
                    return Object.values(this.command.params)
                        .filter((f) => f.component)
                        .slice(0, this._inlineFieldsSize)
                }

                if(this._group === 'secondary') {
                    return Object.values(this.command.params)
                        .filter((f) => f.component)
                        .slice(this._inlineFieldsSize)
                }

                return [];
            },
            filtersCount: function() {                
                return Object.values(this.commandFields)
                        .filter((p) => p.component)
                        .filter(({ value }) => {
                            if (typeof value === 'object' || Array.isArray(value)) {
                                return !this.$lodash.isEmpty(value)
                            }

                            if (value === 0) {
                                return true
                            }

                            return !!value
                        }).length
            }
        },
        mounted: function () {
            if (this._requestOnMounted) {
                this.refresh();
            }
        },
        methods: {
            refresh: function () {
                this.submit()
            },
            submit: async function () {
                try {
                    this.loading = true;

                    let params = this.command.getParams();

                    if (this.command.isPageConstructor()) {
                        params = this.processPageConstructorParams();
                    }

                    const request = await this.command.request(params);
    
                    const items = this.command.convertResponseToArray(request);

                    setTimeout(() => {
                        this.loading = false;
                    }, 800)

                    this.$emit('update-items', items);

                } catch (error) {
                    this.loading = false
                    throw new Error(error)
                }
            },
            processPageConstructorParams: function () {
                const commandParams = this.command.params;
                const processedParams = {};
                
                Object.keys(commandParams).forEach(key => {
                    const param = commandParams[key];

                    if (!param.isJSON) {
                        processedParams[key] = param.value;
                        return;
                    }

                    // Compile all the children params of json parameters into a json and delete children from processedParams
                    const value = {};
                    if (!param.childrenParams || param.childrenParams.length == 0) {
                        return;
                    }

                    for (let child of param.childrenParams) {
                        if (!commandParams[child]) {
                            continue;
                        }

                        let childValue = commandParams[child].value;
                        if (childValue === '' || childValue == undefined) {
                            childValue = null;
                        }

                        if (childValue instanceof Array && !childValue.some(val => val instanceof Object)) {
                            childValue = childValue.join(",");
                        }

                        value[child] = childValue;
                        delete processedParams[child];
                    }

                    processedParams[key] = JSON.stringify(value);
                });

                return processedParams;
            },
            clear: function () {
                Object.values(this.command.params)
                    .filter((f) => f.component)
                    .forEach(f => f.value = undefined)
            },
        },
    };
</script>
