<template>
    <div v-if='command.showFilters && !Array.isArray(command.fields)' style='padding-left: 5px !important;'>
        <a-form v-if='exhibitionMode === "inline"' ref='formInline'>
            <a-row dense justify='end' align='baseline' style='padding-left: 5px !important; padding-right: 5px !important;'>
                <a-col v-for='field in commandFields' :key='Array.isArray(field.name) ? field.name.join(":") : field.name' :cols='12/mainCommandFields.length' class='py-0' >
                    <component
                            :is='field.component'
                            v-if='field.construct && field.component'
                            :ref='Array.isArray(field.name) ? field.name.join(":") : field.name'
                            v-model='field.value'
                            v-bind='field.props'
                            v-on='field.events' />
                </a-col>
            </a-row>
        </a-form>
        <a-filter-sidebar
                v-else-if='exhibitionMode === "sidebar"'
                ref='filterSidebar'
                v-bind='sidebarProps'
                :active-filters='activeFilters'
                :filters='commandFields && !$_aura.isEmpty(commandFields)'
                :fab='_fab'
                :position='_fab ? "fixed" : "absolute"'
                :loading='loading'
                :refresh-color='_refreshColor || "dark"'
                :filter-color='_filterColor || "primary"'
                :filter-color-icon='_filterBackgroundColor || "white"'
                @refresh='refresh()'
                @apply='refresh()'
                @clear='clear()'>
                <template #card-header>
                    <slot name='drawer-header' />
                </template>
            <a-form ref='form'>
                <div v-for='field in commandFields' :key='Array.isArray(field.name) ? field.name.join(":") : field.name'>
                    <component
                            :is='field.component'
                            v-if='field.construct && field.component'
                            :ref='Array.isArray(field.name) ? field.name.join(":") : field.name'
                            v-model='field.value'
                            v-bind='field.props'
                            v-on='field.events' />
                </div>
            </a-form>
        </a-filter-sidebar>
    </div>
</template>

<script>
    export default {
        name: 'ADynamicCommandFilter',
        props: {
            _command: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            _fab: {
                type: Boolean,
                default: true,
            },
            _filterColor: {
                type: String,
                default: null,
            },
            _filterBackgroundColor: {
                type: String,
                default: null,
            },
            _refreshColor: {
                type: String,
                default: null,
            },
            filterGroup: {
                type: String,
                default: 'all',
            },
            exhibitionMode: {
                type: String,
                default: 'sidebar'
            },
            _inlineFieldsSize:{
                type: Number,
                default: 3
            },
            _sidebarProps: {
                type: Object,
                default: () => ({})
            },
            _loading: {
                type: Boolean,
                default: null
            },
        },
        data: function () {
            return {
                innerLoading: false,
                activeFilters: 0,
                command: this._command,
            };
        },
        watch: {
            _command: function (value) {
                this.command = value;
                this.$forceUpdate();
            },
        },
        computed: {
            loading: {
                get: function () {
                    if (this._loading !== null) {
                        return this._loading
                    }

                    return this.innerLoading;
                },
                set: function (value) {
                    if (this._loading !== null) {
                        this.$emit('update:_loading', value)
                        return
                    }

                    this.innerLoading = value;
                },
            },
            sidebarProps: function () {
                if (this.command && this.command.sidebarProps) {
                    return {
                        ...this.command.sidebarProps,
                        ...this._sidebarProps,
                    }
                }

                return this._sidebarProps
            },
            mainCommandFields: function () {
                return Object.values(this.command.fields)
                    .filter((f) => f.construct && f.component)
                    .slice(0, this._inlineFieldsSize)
            },
            otherFields: function () {
                return Object.values(this.command.fields)
                    .filter((f) => f.construct && f.component)
                    .slice(this._inlineFieldsSize)
            },
            commandFields: function () {

                if(this.filterGroup === 'all') {
                    return this.command.fields;
                }

                if(this.filterGroup === 'main') {
                    return this.mainCommandFields;
                }

                if(this.filterGroup === 'secondary') {
                    return this.otherFields;
                }

                return [];
            },
        },
        created: function () {
            this.load();
        },
        mounted: function () {
            if (this.$refs.form) {
                this.$refs.form.validate();
            }
            if (this.$refs.formInline) {
                this.$refs.formInline.validate();
            }
            if (this.command.initializeLoading  && this.exhibitionMode !== "inline") {
                this.$nextTick(() => {
                    this.refresh(true);
                });
            }
        },
        methods: {
            load: async function () {
                const initValue = {};
                
                Object.entries(this.command.fields).forEach(([key, value]) => {
                    initValue[key] = this.$_aura.cloneDeep(value.value);
                });
                
                this.command.initValue = initValue;
                
                this.command.refreshData = this.refresh;
                
                this.command.clearData = this.clear;

                await this.execDependsHandler();
            },
            execDependsHandler: async function () {
                const promises = [];
                Object.values(this.command.fields).forEach(field => {
                    field.onChange = (value => this.onDynamicComponentChange(field, value));
                    promises.push(this.onDynamicComponentChange(field, null, true));
                });
                await Promise.all(promises);
            },
            onDynamicComponentChange: async function ({ name, events }, event, disableEvent) {
                const key = Array.isArray(name) ? name : [name];
                const promises = [];
                Object.values(this.command.fields).forEach(field => {
                    if (field.dependsOn) {
                        const depends = field.dependsOn.filter(value => key.some(el => value.fieldName.includes(el)));
                        depends.forEach(value => {
                            promises.push(value.handler.call(this.command, this.command.fields, this.command.methods));
                        });
                    }
                });
                await Promise.all(promises);
                if (events && events.input && !disableEvent) {
                    await events.input(event);
                }
                this.$forceUpdate();
            },
            refresh: async function (silent, noResetPage) {
                try {
                    this.loading = true;
                    this.activeFilters = 0;
                    this.$_aura.getArray(() => this.command.fields)
                        .forEach(f => {
                            if (f.value !== null && f.value !== undefined && f.construct && f.component) {
                                this.activeFilters++;
                            }
                        });
                    const resultRequireRequest = await this.command.requireRequest.call(this.command, this.command.fields, this.command.methods);
                    if (!silent || (resultRequireRequest != null && typeof resultRequireRequest !== 'string' && !!resultRequireRequest)) {
                        const response = await this.command.request(noResetPage);
                        if (!this.command.noSetProvider) {
                            const data = response ? this.$_aura.get(response.RAIZ, this.command.node) : null;
                            const items = this.$_aura.getArray(() => data);
                            this.$emit('updateValues', items);
                        }
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 800)
                } catch (e) {
                    this.loading = false;
                    throw e;
                }
            },
            clear: async function () {
                Object.entries(this.command.initValue).forEach(([key, value]) => {
                    this.command.fields[key].value = this.$_aura.cloneDeep(value);
                });
                this.$forceUpdate();
            },
            toggleSidebar: function () {
                if (this.$refs.filterSidebar) {
                    this.$refs.filterSidebar.toggleVisible()
                }
            },
        },
    };
</script>
